// import custom components and functions
import CardLayout from '../../../../ui/CardLayout';

function FounderCard(props) {
  const { name, position, imgSrc } = props;
  return (
    <div className="founder__card__wrapper container align__center">
      <CardLayout>
        <img src={imgSrc} className="img" alt={name + ' ' + position} />
      </CardLayout>
      <h3 className="founder__card__title">{name}</h3>
      <p className="founder__card__text">{position}</p>
    </div>
  );
}

export default FounderCard;
