import '../../../styles/icons.scss';

function LogoIcon() {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 436.8 90">
      <g id="Group_21627" transform="translate(-104 -1834)">
        <g id="Group_21526" transform="translate(-20.23)">
          <g id="CarbonStack_icon_RZ_vector" transform="translate(124.23 1834)">
            <path
              id="Path_44"
              className="st0"
              d="M64.5,61.6V45.2c0-3.9-1.1-7.1-3.2-9.3l-4.6,2.7l0.1,0.1c1.7,1,2.7,3.4,2.7,6.6v16.4
				c0,3.2-1,5.6-2.7,6.6c-1.7,1-4.3,0.7-7-0.9L35.6,59c-2.7-1.6-4.3-3.6-4.3-5.6c0.1-1.4,0.7-2.8,1.8-3.8l-1.2-0.7
				c-1.1-0.6-2-1.3-3-2.1c-1.6,1.8-2.5,4.1-2.6,6.5c0,3.9,2.4,7.4,6.8,9.9l14.2,8.2c4.4,2.5,8.6,2.9,12,0.9
				C62.7,70.5,64.5,66.6,64.5,61.6z"
            />
            <path
              id="Path_45"
              className="st0"
              d="M78.9,53.2V36.8c0-5.1-1.8-8.9-5.2-10.8c-3.4-1.9-7.6-1.6-12,0.9l-14.2,8.2
				c-3.4,1.9-5.6,4.5-6.4,7.3l4.5,2.6c0-2,1.6-4,4.3-5.6l14.2-8.2c2.7-1.6,5.3-1.9,7-0.9s2.7,3.4,2.7,6.6v16.4c0,3.2-1,5.6-2.7,6.6
				c-1.2,0.6-2.7,0.8-4,0.3v1.5c0,1.2-0.1,2.4-0.3,3.5c2.3,0.5,4.7,0.1,6.8-1.1C77.1,62.1,78.9,58.3,78.9,53.2z"
            />
            <path
              id="Path_46"
              className="st0"
              d="M56.9,51.5c-1.7,0.9-4.3,0.6-7-1l-14.2-8.2c-2.7-1.6-4.3-3.6-4.3-5.6c0-2,1.6-4.1,4.3-5.6
				l14.2-8.2c2.7-1.6,5.3-1.9,7-0.9c1.1,0.7,2,1.9,2.3,3.2l1.1-0.7c1.1-0.6,2.2-1.1,3.3-1.6c-0.8-2.2-2.3-4.1-4.3-5.3
				c-3.3-1.9-7.6-1.6-12,0.9l-14.2,8.2c-4.4,2.5-6.8,6.1-6.8,9.9s2.4,7.4,6.8,9.9l14.2,8.2c3.3,1.9,6.6,2.6,9.5,1.9L56.9,51.5z"
            />
            <path
              id="Path_47"
              className="st0"
              d="M53.1,85.2c-2.2,0-4.4-0.5-6.4-1.5L22.8,69.9c-3.8-2.4-6.2-6.5-6.4-11V31.2
				c0.2-4.5,2.6-8.6,6.4-11L46.7,6.4c4-2.1,8.8-2.1,12.8,0l23.9,13.8c3.8,2.4,6.2,6.5,6.4,11v27.6c-0.2,4.5-2.6,8.6-6.4,11
				L59.5,83.7C57.5,84.7,55.3,85.3,53.1,85.2z M53.1,9.1c-1.5,0-2.9,0.3-4.2,1L25,23.9c-2.5,1.7-4,4.4-4.2,7.3v27.6
				c0.2,3,1.8,5.7,4.2,7.3l23.9,13.8c2.7,1.3,5.8,1.3,8.4,0l23.9-13.8c2.5-1.7,4-4.4,4.2-7.3V31.2c-0.2-3-1.8-5.7-4.2-7.3L57.3,10.1
				C56,9.4,54.6,9.1,53.1,9.1z"
            />
          </g>
        </g>
        <text transform="matrix(1 0 0 1 212.5161 1895.9674)" className="st0 st1 st2 st3">
          CarbonStack
        </text>
      </g>
    </svg>
  );
}

export default LogoIcon;
