// import custom components and functions
import PACard from './PACard';
import MainWrapper from '../../../../layout/MainWrapper';

//import assets
import paCardIcon1 from '../../../../../assets/startingPage/remoteSensing.svg';
import paCardIcon2 from '../../../../../assets/startingPage/cardIcon6.svg';
import paCardIcon3 from '../../../../../assets/startingPage/CSDCardIcon4.svg';
import arrow from '../../../../../assets/startingPage/arrowLightGreen.svg';

function PermanenceAdditionality(props) {
  const { cards, text, title } = props.textData;
  const { card1, card2, card3 } = cards;
  return (
    <section className="permanence__additionality" id="permanance-additionality">
      <MainWrapper>
        <div className="main__info__block">
          <h2 className="main__info__title">{title}</h2>
          <p className="main__info__subtitle">{text}</p>
        </div>

        <div className="container align__start justify__center pa__cards">
          <PACard className="rs" title={card1.title} text={card1.text} img={paCardIcon1} />
          <div className="img__block">
            <img src={arrow} className="img" alt="arrow" />
          </div>
          <PACard className="em" title={card2.title} text={card2.text} img={paCardIcon2} />
          <div className="img__block">
            <img src={arrow} className="img" alt="arrow" />
          </div>
          <PACard className="crt" title={card3.title} text={card3.text} img={paCardIcon3} />
        </div>
      </MainWrapper>
    </section>
  );
}

export default PermanenceAdditionality;
