// import custom components and functions
import MainWrapper from '../../../../layout/MainWrapper';
import CaseCard from './CaseCard';

//import assets
import prMap from '../../../../../assets/ourImpact/germanyProjectMap.svg';
import trees from '../../../../../assets/ourImpact/csaCard3.svg';

function Case(props) {
  const { cards, primary, text, title, subtitle, subtitle2 } = props.textData;
  const { card1, card2, card3, card4, title1, title2 } = cards;

  return (
    <section className="case">
      <MainWrapper>
        <div className="container justify__center align__center">
          <div className="case__info__block">
            <div className="title__block">
              <h2>
                <span className="primary">{title}</span>
              </h2>
              <p className="main__text">
                {subtitle} <span className="primary">{primary}</span> {subtitle2 ? subtitle2 : ''}
              </p>
            </div>
            <p className="info__text">{text}</p>
            <div className="img__block mobile">
              <img src={prMap} alt="Germany project map" className="img" />
            </div>
            <div className="info__block">
              <h3 className="main__text">{title1}</h3>
              <div className="info__container container justify__center">
                <CaseCard imgSrc={trees} title={card1.title} textData={card1.primary} />
                <CaseCard title={card2.title} textData={card2.primary} />
              </div>
            </div>
            <div className="info__block">
              <h3 className="main__text">{title2}</h3>
              <div className="info__container container justify__center">
                <CaseCard imgSrc={trees} title={card3.title} textData={card3.primary} />
                <CaseCard title={card4.title} textData={card4.primary} />
              </div>
            </div>
          </div>
          <div className="img__block">
            <img src={prMap} alt="Germany project map" className="img" />
          </div>
        </div>
      </MainWrapper>
    </section>
  );
}

export default Case;
