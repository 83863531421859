// import custom components and functions
import MainWrapper from '../../../../layout/MainWrapper';
import CardLayout from '../../../../ui/CardLayout';
import CSDCards from './CSDCards';
import CSACards from './CSACards';

function CSReduceAvoid(props) {
  const { button, csaCard, csdCard, primaryTitle, primaryText, text, title } = props.textData;

  return (
    <section className="reduce__avoid">
      <MainWrapper>
        <div className="title__block">
          <h2>
            {title} <span className="primary">{primaryTitle}</span>
          </h2>
          <p className="section__title__subtext">
            {text} <span className="primary">{primaryText}</span>
          </p>
        </div>
        <div className="section__info__block container align__stretch justify__between">
          <CardLayout className="left__side__card csd">
            <div className="title__block">
              <p className="title__subtext">{csdCard.subtitle}</p>
              <h3>
                <span className="primary">{csdCard.title}</span>
              </h3>
            </div>
            <CSDCards textData={csdCard.csdCards} />
          </CardLayout>
          <CardLayout className="right__side__card csa">
            <div className="title__block">
              <p className="title__subtext">{csaCard.subtitle}</p>
              <h3>{csaCard.title}</h3>
            </div>
            <CSACards textData={csaCard.csaCards} />
          </CardLayout>
        </div>
      </MainWrapper>
    </section>
  );
}

export default CSReduceAvoid;
