// import lib components and functions
import { Helmet } from 'react-helmet-async';

function MetaTags(props) {
  const { author, description, keywords, language, robots, pageTitle, title } = props.metaData;
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="title" content={title}></meta>
      <meta name="description" content={description}></meta>
      <meta name="keywords" content={keywords}></meta>
      <meta name="robots" content={robots}></meta>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"></meta>
      <meta name="language" content={language}></meta>
      <meta name="author" content={author}></meta>
    </Helmet>
  );
}

export default MetaTags;
