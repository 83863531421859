// import custom components and functions
import MainWrapper from '../../../layout/MainWrapper';
import ImpactVideo from '../../../ui/ImpactVideo';

//import assets
import '../../../../styles/ourImpact.scss';
import '../../../../styles/icons.scss';
import poster from '../../../../assets/ourImpact/ourImpactPoster.png';

function Hero(props) {
  const { title, primary, subtitle } = props.textData;

  return (
    <section className="impact__hero">
      <MainWrapper>
        <div className="title__block">
          <h1>
            {title} <span className="primary">{primary}</span>
          </h1>
          <p>{subtitle}</p>
        </div>
        <div className="video__wrap">
          <ImpactVideo
            poster={poster}
            // src="https://youtu.be/eg5m7-xzsg0?&autoplay=1&modestbranding=1&showinfo=0&start=0&enablejsapi=1"
            src="https://youtu.be/so_N-ysMcu0"
	    videoId="so_N-ysMcu0"
            switchSound={true}
            className="video__bg"
          />
        </div>
      </MainWrapper>
    </section>
  );
}

export default Hero;
