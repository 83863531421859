// import assets
import SectionImg from './SectionImg';
import MainWrapper from '../../../../layout/MainWrapper';

function NeedToPlant(props) {
  const { button, imgText, primary1, primary2, titleP1, titleP2, titleP3 } = props.textData;

  return (
    <section className="need__to__plant">
      <MainWrapper>
        <div className="container justify__center align__center">
          <div className="img__block">
            <SectionImg imgText={imgText} />
          </div>
          <div className="title__block">
            <h2>
              {titleP1} <span className="primary">{primary1}</span> {titleP2}{' '}
              <span className="primary">{primary2}</span>
              {titleP3 ? titleP3 : ''}!
            </h2>
          </div>
        </div>
        <div className="container justfy__center">
          <a href="mailto:info@carbonstack.de" className="main__section__btn main__link">
            {button}
          </a>
        </div>
      </MainWrapper>
    </section>
  );
}

export default NeedToPlant;
