// import lib components and functions
import React from 'react';

function InfoCard(props) {
  const { img, percentages, title, text } = props;
  return (
    <div className="info__card container align__center">
      <div className="img__block">
        <img src={img} className="img" alt={title} />
      </div>
      <div className="info__card__block container align__center">
        <span className="primary">{percentages}%</span>
        <h4 className="info__card__title">{title}</h4>
        <p className="info__text">{text}</p>
      </div>
    </div>
  );
}

export default InfoCard;
