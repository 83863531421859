// import custom components and functions
import MainWrapper from '../../../../layout/MainWrapper';
import FounderCard from './FounderCard';

// import assets
import founderPhoto1 from '../../../../../assets/aboutUs/MensingAnna_CarbonStack-52.jpg';
import founderPhoto2 from '../../../../../assets/aboutUs/MensingAnna_CarbonStack-60.jpg';
import founderPhoto3 from '../../../../../assets/aboutUs/MensingAnna_CarbonStack-48.jpg';
import founderPhoto4 from '../../../../../assets/aboutUs/MensingAnna_CarbonStack-80.jpg';

function Founders(props) {
  const foundersData = {
    founder1: {
      name: 'Julian Kakarott',
      position: 'CEO'
    },
    founder2: {
      name: 'Jann Wendt',
      position: 'CIO'
    },
    founder3: {
      name: 'Noah Winneberger',
      position: 'CTO'
    },
    founder4: {
      name: 'Jesper Kolk',
      position: 'CCO'
    }
  };

  const { founder1, founder2, founder3, founder4 } = foundersData;
  const { title, text } = props.textData;

  return (
    <section className="founders">
      <MainWrapper>
        <div className="title__block about__us">
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
        <div className="container align__center justify__center founders__container">
          <FounderCard name={founder1.name} position={founder1.position} imgSrc={founderPhoto1} />
          <FounderCard name={founder2.name} position={founder2.position} imgSrc={founderPhoto2} />
          <FounderCard name={founder3.name} position={founder3.position} imgSrc={founderPhoto3} />
          <FounderCard name={founder4.name} position={founder4.position} imgSrc={founderPhoto4} />
        </div>
      </MainWrapper>
    </section>
  );
}

export default Founders;
