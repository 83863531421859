import React from 'react';

function PACard(props) {
  const { title, text, img, className } = props;
  return (
    <div className={className + ' ' + 'pa__card__wrapper container align__center'}>
      <div className="pa__img__block">
        <img src={img} className="img" alt={title} />
      </div>
      <div className="pa__info__block">
        <h3 className="pa__card__title center">{title}</h3>
        <p className="info__text center">{text}</p>
      </div>
    </div>
  );
}

export default PACard;
