// import custom components and functions
import CSDCard from './CSDCard';

// import assets
import CSDCardIcon1 from '../../../../../assets/startingPage/CSDCardIcon1.svg';
import CSDCardIcon2 from '../../../../../assets/startingPage/CSDCardIcon2.svg';
import CSDCardIcon3 from '../../../../../assets/startingPage/CSDCardIcon3.svg';
import CSDCardIcon4 from '../../../../../assets/startingPage/CSDCardIcon4.svg';
import arrowIcon from '../../../../../assets/startingPage/arrow.svg';

function csdCards(props) {
  const { card1, card2, card3, card4 } = props.textData;

  return (
    <div className="csd__cards">
      <CSDCard img={CSDCardIcon1} className="faf" title={card1.title} text={card1.text} />
      <div className="img__block">
        <img src={arrowIcon} className="csd__card__arrow img" alt="arrow icon" />
      </div>
      <CSDCard img={CSDCardIcon2} className="os" title={card2.title} text={card2.text} />
      <div className="img__block">
        <img src={arrowIcon} className="csd__card__arrow img" alt="arrow icon" />
      </div>
      <CSDCard img={CSDCardIcon3} className="ac" title={card3.title} text={card3.text} />
      <div className="img__block">
        <img src={arrowIcon} className="csd__card__arrow img" alt="arrow icon" />
      </div>
      <CSDCard img={CSDCardIcon4} className="cc" title={card4.title} text={card4.text} />
    </div>
  );
}

export default csdCards;
