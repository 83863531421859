// import custom components and functions
import MainWrapper from '../../../layout/MainWrapper';
import CardLayout from '../../../ui/CardLayout';

//import assets
import img1 from '../../../../assets/ourImpact/img1.jpeg';
import img2 from '../../../../assets/ourImpact/img2.png';
import img3 from '../../../../assets/ourImpact/img3.jpeg';
import img4 from '../../../../assets/ourImpact/img4.jpeg';
import img5 from '../../../../assets/ourImpact/img5.jpeg';
import img6 from '../../../../assets/ourImpact/img6.jpeg';

function ImpactGrid(props) {
  const { button, primary, text, subtitle } = props.textData;

  return (
    <section className="impact__grid">
      <MainWrapper>
        <div className="impact__grid__wrapper">
          <div className="grid__title__block">
            <h2>
              <span className="primary">{primary}</span>
            </h2>
            <p>{text}</p>
          </div>
          <div className="container align__start justify__between fs__cnr">
            <CardLayout>
              <img src={img1} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img2} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img3} className="img" alt="Our impact image" />
            </CardLayout>
          </div>
          <div className="container align__end justify__between sc__cnr">
            <CardLayout>
              <img src={img4} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img5} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img6} className="img" alt="Our impact image" />
            </CardLayout>
          </div>
          <div className="container align__start justify__between fs__cnr mobile">
            <CardLayout>
              <img src={img1} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img2} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img5} className="img" alt="Our impact image" />
            </CardLayout>
          </div>
          <div className="container align__end justify__between sc__cnr mobile">
            <CardLayout>
              <img src={img6} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img4} className="img" alt="Our impact image" />
            </CardLayout>
            <CardLayout>
              <img src={img3} className="img" alt="Our impact image" />
            </CardLayout>
          </div>
        </div>
      </MainWrapper>
    </section>
  );
}

export default ImpactGrid;
