// import lib components and functions
import React from 'react';

function CSDCard(props) {
  const { img, title, text, className } = props;
  return (
    <div className={className + ' ' + 'csd__card__wrap'}>
      <div className="csd__card container align__center">
        <div className="csd__icon__block container align__center justify__center">
          <img src={img} alt={title} />
        </div>
        <div className="csd__info__block">
          <h4 className="csd__info__title">{title}</h4>
          <p className="csd info__text">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default CSDCard;
