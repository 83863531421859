// import lib components and functions
import { Link } from 'react-router-dom';

// import custom components
import LogoIcon from '../ui/icons/LogoIcon';

function Logo(props) {
  return (
    <div className="logo">
      <Link to={`/${props.langId}/`}>
        <div className="logo__img">
          <LogoIcon />
        </div>
      </Link>
    </div>
  );
}

export default Logo;
