import React, { useState } from 'react';
import '../../styles/ContactForm.scss';

const ContactForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    jobTitle: '',
    phone: '',
    compensationNeed: '',
    reason: [],
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setFormData({ ...formData, reason: [...formData.reason, name] });
    } else {
      setFormData({ ...formData, reason: formData.reason.filter(item => item !== name) });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://carbonstack.io/contactform/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        alert('Vielen Dank für Ihre Nachricht!');
        onSubmit();
      } else {
        alert('Leider konnte Ihre Nachricht nicht verschickt werden.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Leider konnte Ihre Nachricht nicht verschickt werden.');
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form-wrapper">
        <h1 className="form-title">Nehmen Sie Kontakt auf</h1>

        <div className="form-group">
          <label className="form-label">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">E-Mail</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Name des Unternehmens</label>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="form-input"
            required
          />
        </div>

        <div className="form-group">
          <label className="form-label">Job Titel (optional)</label>
          <input
            type="text"
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Telefon (optional)</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-input"
          />
        </div>

        <div className="form-group">
          <label className="form-label">Ihr jährlicher Kompensationsbedarf in tCO2e</label>
          <select
            name="compensationNeed"
            value={formData.compensationNeed}
            onChange={handleChange}
            className="form-select"
            required
          >
            <option value="100 - 1.000">100 - 1.000</option>
            <option value="1.000 - 5.000">1.000 - 5.000</option>
            <option value="5.000 - 10.000">5.000 - 10.000</option>
            <option value="> 10.000">&gt; 10.000</option>
          </select>
        </div>

        <div className="form-group">
          <label className="form-label">Ihr Grund für die Kontaktaufnahme</label>
          <div className="checkbox-group">
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                name="Kauf von CO2-Zertifikaten aus deutschen Wäldern"
                checked={formData.reason.includes('Kauf von CO2-Zertifikaten aus deutschen Wäldern')}
                onChange={handleCheckboxChange}
                className="checkbox"
              />
              <label className="checkbox-label">Kauf von CO2-Zertifikaten aus deutschen Wäldern</label>
            </div>
            <div className="checkbox-wrapper">
              <input
                type="checkbox"
                name="Individuelle Entwicklung von Klimaschutzprojekten"
                checked={formData.reason.includes('Individuelle Entwicklung von Klimaschutzprojekten')}
                onChange={handleCheckboxChange}
                className="checkbox"
              />
              <label className="checkbox-label">Individuelle Entwicklung von Klimaschutzprojekten</label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="form-label">Ihre Nachricht (optional)</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="form-textarea"
          ></textarea>
        </div>

        <button type="submit" className="submit-button">
          Absenden
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
