// import lib components and functions
import React from 'react';

const CelsiusAnimation = React.forwardRef(function animation(props, ref) {
  return (
    <div className={props.theme + ' ' + 'animation__wrapper'} ref={ref}>
      <div className={props.animationState ? 'animate animated' : 'animated'}></div>
      <svg width="272" height="777" viewBox="0 0 272 777">
        <g id="Group_24447" data-name="Group 24447" transform="translate(-199 -2310)">
          <g
            id="Rectangle_5005"
            data-name="Rectangle 5005"
            transform="translate(199 2310)"
            fill="none"
            stroke=""
            strokeWidth="15">
            <rect width="146" height="777" rx="73" stroke="none" />
            <rect x="7.5" y="7.5" width="131" height="762" rx="65.5" fill="none" />
          </g>
          <rect
            id="Rectangle_5006"
            data-name="Rectangle 5006"
            width="146"
            height="234"
            rx="73"
            transform="translate(199 2853)"
            fill="#d60036"
          />
          <rect
            id="Rectangle_5007"
            data-name="Rectangle 5007"
            width="146"
            height="215"
            transform="translate(199 2733)"
            fill="#d60036"
          />
          <g id="Group_24263" data-name="Group 24263" transform="translate(299.704 2441.69)">
            <line
              id="Line_1065"
              data-name="Line 1065"
              x2="33.72"
              transform="translate(4.557 506.712)"
              fill="none"
              stroke={props.theme === 'light' ? '#000' : '#fff'}
              strokeLinecap="round"
              strokeWidth="10"
            />
            <line
              id="Line_1066"
              data-name="Line 1066"
              x2="33.72"
              transform="translate(3.645 380.034)"
              fill="none"
              stroke={props.theme === 'light' ? '#000' : '#fff'}
              strokeLinecap="round"
              strokeWidth="10"
            />
            <line
              id="Line_1067"
              data-name="Line 1067"
              x2="33.72"
              transform="translate(1.823 253.356)"
              fill="none"
              stroke={props.theme === 'light' ? '#000' : '#fff'}
              strokeLinecap="round"
              strokeWidth="10"
            />
            <line
              id="Line_1068"
              data-name="Line 1068"
              x2="33.72"
              transform="translate(0.911 126.678)"
              fill="none"
              stroke={props.theme === 'light' ? '#000' : '#fff'}
              strokeLinecap="round"
              strokeWidth="10"
            />
            <line
              id="Line_1069"
              data-name="Line 1069"
              x2="33.72"
              transform="translate(0)"
              fill="none"
              stroke={props.theme === 'light' ? '#000' : '#fff'}
              strokeLinecap="round"
              strokeWidth="10"
            />
          </g>
          <text
            id="_1_5_C"
            data-name="1,5°C"
            transform="translate(416 2710)"
            fontWeight="700"
            fill={props.theme === 'light' ? '#000' : '#fff'}>
            <tspan x="-54.632" y="0">
              1,5°C
            </tspan>
          </text>
          <text
            id="_2_C"
            data-name="2°C"
            transform="translate(399 2455)"
            fontWeight="700"
            fill={props.theme === 'light' ? '#000' : '#fff'}>
            <tspan x="-37.716" y="0">
              2°C
            </tspan>
          </text>
          <g
            id="Rectangle_5088"
            data-name="Rectangle 5088"
            transform="translate(199 2310)"
            fill="none"
            stroke={props.theme === 'light' ? '#000' : '#fff'}
            strokeWidth="20">
            <rect width="146" height="777" rx="73" stroke="none" />
            <rect x="10" y="10" width="126" height="757" rx="63" fill="none" />
          </g>
        </g>
      </svg>
    </div>
  );
});

export default CelsiusAnimation;
