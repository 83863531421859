// import lib components and functions
import { useContext } from 'react';

// import custom components and functions
import MetaTags from '../MetaTags/MetaTags';
import Layout from '../../layout/Layout';
import Hero from './sections/Hero';
import RoadToNetZero from './sections/RoadToNetZero';
import Founders from './sections/FoundersSection/Founders';
import Partners from './sections/Partners';
import { LanguageContext } from '../../../store/switchLanguageContext';
import { useTranslation } from '../../../store/switchTranslation';
import { aboutUsPageData } from '../../../store/aboutUs';

function AboutUs() {
  const translation = useTranslation(aboutUsPageData, LanguageContext);
  const { founders, hero, partners, roadToNetZero, metaData } = translation;

  const langCtx = useContext(LanguageContext);
  const langId = langCtx.langId;
  return (
    <>
      <MetaTags metaData={metaData} />
      <Layout>
        <Hero textData={hero} langId={langId} />
	<Founders textData={founders} />
        <RoadToNetZero textData={roadToNetZero} />
        <Partners textData={partners} />
      </Layout>
    </>
  );
}

export default AboutUs;
