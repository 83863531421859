import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Home from './components/pages/home/Home';
import AboutUs from './components/pages/aboutUs/AboutUs';
import OurImpact from './components/pages/ourImpact/OurImpact';
import Impressum from './components/pages/impressum/Impressum';
import Privacy from 'components/pages/privacyPolicy/Privacy';
import { LanguageContextProvider } from './store/switchLanguageContext';
import { ThemeContextProvider } from './store/switchThemeContext';

//import assets
import './styles/styles.scss';

function App() {
  return (
    <HelmetProvider>
      <LanguageContextProvider>
        <ThemeContextProvider>
          <div className="App">
            <Routes>
              <Route path="/:langId" element={<Home />} />
              <Route path="/:langId/about-us" element={<AboutUs />} />
              <Route path="/:langId/our-impact" element={<OurImpact />} />
              <Route path="/:langId/imprint" element={<Impressum />} />
              <Route path="/:langId/privacy" element={<Privacy />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </div>
        </ThemeContextProvider>
      </LanguageContextProvider>
    </HelmetProvider>
  );
}

export default App;
