function SoundSwitchIcon(props) {
  const { isMuted, soundStateHandler } = props;

  return (
    <div className={isMuted ? 'sound__switch__wrapper off' : 'sound__switch__wrapper'}>
      <button onClick={soundStateHandler}>
        <div className="icon__wrapper">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 21C18.8658 20.9975 18.7347 20.9596 18.62 20.89L9.46 15.5H5C4.80189 15.4974 4.61263 15.4176 4.47253 15.2775C4.33244 15.1374 4.25259 14.9481 4.25 14.75V9.25001C4.25259 9.05191 4.33244 8.86264 4.47253 8.72255C4.61263 8.58245 4.80189 8.5026 5 8.50001H9.46L18.62 3.12001C18.734 3.05419 18.8633 3.01953 18.995 3.01953C19.1267 3.01953 19.256 3.05419 19.37 3.12001C19.4843 3.18602 19.5795 3.28067 19.6462 3.39465C19.7128 3.50862 19.7486 3.63799 19.75 3.77001V20.24C19.7486 20.372 19.7128 20.5014 19.6462 20.6154C19.5795 20.7294 19.4843 20.824 19.37 20.89C19.2577 20.9567 19.1305 20.9945 19 21ZM5.75 14H9.67C9.8033 13.999 9.93447 14.0335 10.05 14.1L18.25 18.92V5.08001L10.05 9.90001C9.93447 9.96652 9.8033 10.001 9.67 10H5.75V14Z"
              fill="#fff"
            />
          </svg>
        </div>
      </button>
    </div>
  );
}

export default SoundSwitchIcon;
