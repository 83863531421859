// import custom components and functions
import MainWrapper from '../../../../layout/MainWrapper';

//import assets
import mainImg from '../../../../../assets/startingPage/blockchainNetwork.svg';
import logo1 from '../../../../../assets/startingPage/algorandLogo.svg';
import logoLight1 from '../../../../../assets/startingPage/algorandLogoLight.svg';

function TransparentOffset(props) {
  const { primary, subtitle, text, title, trustedByTitle } = props.textData;
  return (
    <section className="transparent__offset">
      <MainWrapper>
        <div className="title__block">
          <h2>
            {title} <span className="primary">{primary}</span>
          </h2>
          <p className="section__title__subtext">{subtitle}</p>
        </div>
        <div className="container align__center justify__center">
          <div className="main__info__block">
            <p className="info__text">{text}</p>
            <div className="main__img__block mobile">
              <img src={mainImg} className="img" alt="transparent offset" />
            </div>
          </div>
          <div className="main__img__block">
            <img src={mainImg} className="img" alt="transparent offset" />
          </div>
        </div>
      </MainWrapper>
    </section>
  );
}

export default TransparentOffset;
