// import custom components and functions
import MainWrapper from '../../../../layout/MainWrapper';
import CardLayout from '../../../../ui/CardLayout';

//import assets
import img1 from '../../../../../assets/startingPage/satellite1.png';
import img2 from '../../../../../assets/startingPage/satellite2.png';
import img3 from '../../../../../assets/startingPage/satellite3.png';

function ObserveFromSpace(props) {
  const { button, primary1, primary2, primary3, subtitle, text1, text2, title } = props.textData;
  return (
    <section className="observe__from__space">
      <MainWrapper>
        <div className="title__block">
          <h2>
            {title} <span className="primary">{primary1}</span>
          </h2>
          <p className="section__title__subtext">{subtitle}</p>
        </div>

        <div className="container justify__center align__center fs__container">
          <CardLayout>
            <img src={img1} className="img" alt="satellite image" />
          </CardLayout>
          <div className="info__block">
            <h3 className="info__block__title">{primary2}</h3>
            <p className="info__text">{text1}</p>
          </div>
        </div>
        <div className="container justify__center align__center sc__conatainer">
          <div className="info__block">
            <h3 className="info__block__title">
              {primary3} <span>cm</span>
            </h3>
            <p className="info__text">{text2}</p>
            <a
              href="https://up42.com/case-study/carbonstack-offsets-co2-emissions-satellite-imagery"
              className="main__link">
              {button}
            </a>
          </div>

          <CardLayout>
            <img src={img2} className="img" alt="satellite image" />
          </CardLayout>
          <CardLayout>
            <img src={img3} className="img" alt="satellite image" />
          </CardLayout>
        </div>
      </MainWrapper>
    </section>
  );
}

export default ObserveFromSpace;
