// import custom components and functions
import MainWrapper from '../../../layout/MainWrapper';
import CardLayout from '../../../ui/CardLayout';

// import assets
import teamImg from '../../../../assets/aboutUs/team.jpeg';
import '../../../../styles/aboutUs.scss';

function Hero(props) {
  const { button, primary, subtitle, title, title2 } = props.textData;

  return (
    <section className="about__us__hero">
      <MainWrapper>
        <div className="title__block">
          {props.langId === 'de' ? (
            <h1>
              {title}
              <br />
              <span className="primary">{primary}</span>
              {title2}
            </h1>
          ) : (
            <h1>
              {title}
              <br />
              {title2} <span className="primary">{primary}</span>
            </h1>
          )}
          <p>{subtitle}</p>
        </div>
      </MainWrapper>
    </section>
  );
}

export default Hero;
