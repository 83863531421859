// import lib components and functions
import { useEffect, useRef, useState } from 'react';

// import custom components and functions
import CelsiusAnimation from './CelsiusAnimation';
import InfoCard from './InfoCard';
import MainWrapper from '../../../../layout/MainWrapper';

// import assets
import cardIcon1 from '../../../../../assets/startingPage/CSDCardIcon1.svg';
import cardIconLight1 from '../../../../../assets/startingPage/CSDCardIconLight1.svg';
import cardIcon2 from '../../../../../assets/startingPage/cardIcon5.svg';
import cardIcon3 from '../../../../../assets/startingPage/CSDCardIcon3.svg';

function NoWasteTime(props) {
  const { footerText, primary, title, text, cardsBlock } = props.textData;
  const { cards, subtitle } = cardsBlock;
  const { card1, card2, card3 } = cards;

  const animationRef = useRef(null);

  const [animationState, setAnimationState] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', onScrollHandler);
    const thermometerY = animationRef.current.offsetTop;

    function onScrollHandler() {
      const scrollY = window.scrollY;

      if (scrollY - thermometerY > -200) {
        setAnimationState(true);
      }

      if (scrollY - thermometerY > 200) {
        setAnimationState(false);
      }

      if (scrollY - thermometerY < -500) {
        setAnimationState(false);
      }
    }

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, []);

  return (
    <section className="no__waste__time">
      <MainWrapper>
        <div className="container align__center main__section__container">
          <CelsiusAnimation
            ref={animationRef}
            theme={props.theme}
            animationState={animationState}
          />
          <div className="main__section__block">
            <div className="title__block">
              <h2>
                {title} <span className="primary">{primary}</span>
              </h2>
              <p className="info__text">{text}</p>
            </div>
            <div className="main__info__block">
              <h3 className="main__info__title">{cardsBlock.title}</h3>
              <p className="main__info__subtitle">{subtitle}</p>
              <div className="container info__cards__wrap justify__between align__center">
                <InfoCard
                  title={card1.title}
                  percentages={card1.percentages}
                  text={card1.text}
                  img={props.theme === 'light' ? cardIcon1 : cardIconLight1}
                />
                <InfoCard
                  title={card2.title}
                  percentages={card2.percentages}
                  text={card2.text}
                  img={cardIcon2}
                />
                <InfoCard
                  title={card3.title}
                  percentages={card3.percentages}
                  text={card3.text}
                  img={cardIcon3}
                />
              </div>
              <p className="info__text center">{footerText}</p>
            </div>
          </div>
        </div>
      </MainWrapper>
    </section>
  );
}

// function useIsInViewport(ref) {
//   const [isIntersecting, setIsIntersecting] = useState(false);

//   const observer = useMemo(
//     () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
//     []
//   );

//   useEffect(() => {
//     observer.observe(ref.current);

//     return () => {
//       observer.disconnect();
//     };
//   }, [ref, observer]);

//   return isIntersecting;
// }

export default NoWasteTime;
