export const homePageData = {
    en: {
        metaData: {
            author: 'Noah Winneberger',
            description: 'CarbonStack helps companies reach their sustainability goals by developing local afforestation projects and creating transparent carbon credits.',
            keywords: 'sustainability, carbon offsets, afforestation, climate, transformation, carbon neutral, carbon neutrality, zero emissions, emissions, forests, blockchain, algorand, transparency, transparent, German forests, compensation, compensation claim, contribution claim, carbon certificate, satellite, remote sensing, offsets, offsetting',
            language: 'English',
            robots: 'index, follow',
            pageTitle: 'CarbonStack',
            title: 'Providing certainty in an uncertain climate.'
        },
        hero: {
            title: 'CLIMATE PROTECTION ',
            primary: 'FOR GENERATIONS.',
            text: 'We provide certainty in an uncertain climate.'
        },
        avoidReduce: {
            title: 'Avoid, Reduce,',
            primaryTitle: 'CarbonStack.',
            primaryText: 'have certainty in an uncertain climate!',
            button: 'Get in Touch',
            text: 'CarbonStack provides process mining as a service for ecosystems to make data-driven decisions and help businesses ',
            csdCard: {
                title: 'Our Process',
                subtitle: 'Local ecosystems with global potential',
                csdCards: {
                    card1: {
                        title: 'Local Analysis',
                        text: 'Using our CarbonStack Engine, we simulate and analyze ecosystems under different climate scenarios.'
                    },
                    card2: {
                        title: 'Permanent Carbon Sinks',
                        text: 'Based on our climate models, we determine the optimal tree species composition for each site and calculate the CO2 uptake of the forest over decades.'
                    },
                    card3: {
                        title: 'Afforestation of Areas',
                        text: 'Our data is the cornerstone of reforestation to ensure that a permanent and climate resilient mixed forest is created.'
                    },
                    card4: {
                        title: 'Certification & Crediting',
                        text: 'We provide your projects with the necessary security so that an accurate distribution of certificates is ensured.'
                    }
                }
            },
            csaCard: {
                title: 'CarbonStack Engine',
                subtitle: 'We simulate every tree - every single one.',
                csaCards: {
                    card1: {
                        title: 'Local Analysis',
                        text: 'Every ecosystem is different and changes daily. We make it possible to simulate each site specifically to account for each climate and biological process.',
                        button: 'Learn More'
                    },
                    card2: {
                        title: 'Process-based Analysis',
                        text: 'We simulate every biological process in the ecosystem, ensuring that our analysis accounts for every change.',
                        button: 'Learn More'
                    },
                    card3: {
                        title: 'Scalable Data-Provision',
                        text: 'Our simulation data is easy to retrieve and use. Through our infrastructure you receive all data easily and tailored to your problem.'
                    },
                    card4: {
                        title: 'Comprehensive Simulation',
                        text: 'Our engine makes use of the latest technologies in satellite and ground data to provide the most comprehensive analysis.'
                    }
                }
            }
        },
        noTimeToWaste: {
            title: 'NO TIME TO',
            primary: ' WASTE.',
            text: 'Climate change and its effects on our society are considered one of the most existential threats of our time. Only through data-based decisions we will be able to adapt our ecosystems to climate resilience and create permanent CO2 sinks.',
            footerText: `CarbonStack helps you plant the right tree in the right place at the right time. It doesn't matter if you are developing a reforestation project or are adapting your ecosystems, our data will ensure permanence and resilience.`,
            cardsBlock: {
                title: 'We believe in local impact',
                subtitle: 'Global Warming already arrived in Germany. Let’s do something about it.',
                cards: {
                    card1: {
                        title: 'Significant forest damage',
                        text: 'Severe crown thinning',
                        percentages: '37'
                    },
                    card2: {
                        title: 'Endangered',
                        text: 'A warning has been issued for these forest areas',
                        percentages: '42'
                    },
                    card3: {
                        title: 'No Damages',
                        text: 'Healthy forest areas',
                        percentages: '21'
                    }
                }
            }
        },
        needToPlantNow: {
            titleP1: 'WE NEED TO PLANT',
            titleP2: 'TO HAVE FULLY GROWN TREES BY',
            titleP3: null,
            primary1: 'NOW',
            primary2: '2050',
            imgText: 'Trees planted so far',
            button: 'Get in Touch'
        },
        permananceAdditionality: {
            title: 'Permanence & Additionality.',
            text: 'Our CarbonStack Engine enables unprecedented accuracy in determining CO2 uptake capacities of ecosystems.',
            cards: {
                card1: {
                    title: 'Remote Sensing',
                    text: 'We use satellite images to determine the exact size of the damaged area. This is done with both Sentinel and Airbus Pleiades Neo images.'
                },
                card2: {
                    title: 'Environmental Modelling',
                    text: `Our process-based environmental simulation is used to determine the site's carbon uptake over the next 30 to 60 years. Historical weather data, climate models and up to 70 parameters per tree species are used.`
                },
                card3: {
                    title: 'Certification',
                    text: 'Because we know that carbon credits are subject to scepticism, we let independent standards and verifiers look over our work. The result is a compensation claim that can be used for our ESG reporting.'
                }
            }
        },
        transparentOffset: {
            title: 'A trusted infrastructure for',
            primary: 'transparent offsets.',
            subtitle: 'We build a blockchain based platform that enables companies to visualise their impact.',
            text: 'By implementing a private branch of the Algorand Blockchain Infrastructure we actively solve the challenge of having a transparent registry of compensation projects. With a focus on sustainability the Algorand protocol allows us to implement this in the most energy efficient way by utilizing their pure proof of stake algorithm. Using this Blockchain Infrastructure we have created a comprehensive Blockchain based Carbon Registry, documenting compensation projects throughout their entire lifecycle transparently. Furthermore, the decentralized structure of our registry allows for the active participation of external users, backed by a fully decentralized consensus algorithm.',
            trustedByTitle: 'Our Registry is trusted by'
        },
        observeFromSpace: {
            title: 'Observe your impact from',
            subtitle: 'Instead of manual samples, we monitor every single tree of our projects via satellite images. Companies can get a yearly overview of their impact.',
            primary1: 'Space!',
            primary2: '1,5 MILLION TREES',
            primary3: '30',
            text1: 'Were analyzed to improve our simulation through machine learning. We work closely with Airbus and UP42 when it comes to capturing the necessary images.',
            text2: 'Resolution of Airbus Pleiades Neo allows us to identify and classify every single tree on our project sites.',
            button: 'Case Study'
        }
    },
    de: {
        metaData: {
            author: 'Noah Winneberger',
            description: 'CarbonStack hilft Unternehmen, ihre Nachhaltigkeitsziele zu erreichen, indem es lokale Aufforstungsprojekte entwickelt und transparente Emissionsgutschriften erstellt.',
            keywords: 'sustainability, carbon offsets, afforestation, climate, transformation, carbon neutral, carbon neutrality, zero emissions, emissions, forests, blockchain, algorand, transparency, transparent, German forests, compensation, compensation claim, contribution claim, carbon certificate, satellite, remote sensing, offsets, offsetting, klima, netto-null',
            language: 'Deutsch',
            robots: 'index, follow',
            pageTitle: 'CarbonStack',
            title: 'Sicherheit schaffen in einem unsicheren Klima'
        },
        hero: {
            title: 'Klimaschutz. ',
            primary: 'Für Generationen.',
            text: 'Wir schaffen Sicherheit in einem unsicheren Klima.'
        },
        avoidReduce: {
            title: 'Avoid, Reduce, ',
            primaryTitle: 'CarbonStack.',
            primaryText: 'Sicherheit in einem unsichern Klima zu haben!',
            text: 'CarbonStack bietet Process-Mining as a Service für Ökosysteme, um datengetriebene Entscheidungen zu treffen und Unternehmen dabei zu unterstützen ',
            button: 'Kontaktieren Sie uns',
            csdCard: {
                title: 'Unser Vorgehen',
                subtitle: 'Regionale Wälder, mit globaler Klimaschutzwirkung.',
                csdCards: {
                    card1: {
                        title: 'Waldflächenanalyse',
                        text: 'Mithilfe unserer CarbonStack Engine simulieren und analysieren wir Ökosysteme unter verschiedenen Klimaszenarien.'
                    },
                    card2: {
                        title: 'Langfristige Kohlenstoff-Speicher',
                        text: 'Basierend auf unseren Klimamodellen ermitteln wir die optimale Baumartenzusammensetzung für jeden Standort und kalkulieren die CO2-Aufnahme des Waldes über Dekaden.'
                    },
                    card3: {
                        title: 'Aufforstung von Schadflächen',
                        text: 'Unsere Daten bilden den Grundstein der Aufforstung um zu gewährleisten das ein permanenter und klimaresilienter Mischwald entsteht.'
                    },
                    card4: {
                        title: 'Extern validierte CO2-Zertifikate',
                        text: 'Wir geben Ihren Projekten die notwendige Sicherheit, sodass eine akkurate Ausschüttung von Zertifikaten sichergestellt ist.'
                    }
                }
            },
            csaCard: {
                title: 'Unsere CarbonStack Engine',
                subtitle: 'Wir simulieren jeden Baum. Wirklich Jeden.',
                csaCards: {
                    card1: {
                        title: 'Lokale Simulation',
                        text: 'Jedes Ökosystem ist anders und verändert sich täglich. Wir machen es möglich jeden Standort spezifisch zu simulieren um jedes Klima und jeden biologischen Prozess zu berücksichtigen.',
                        button: 'Mehr erfahren'
                    },
                    card2: {
                        title: 'Prozess-basierte Analyse',
                        text: 'Wir simulieren jeden biologischen Prozess im Ökosystem und stellen so sicher, dass unsere Analyse jede Veränderung berücksichtigt.',
                        button: 'Mehr erfahren'
                    },
                    card3: {
                        title: 'Skalierbare Anwendung',
                        text: 'Unsere Simulationsdaten sind einfach abrufbar und einsetzbar. Durch unsere Infrastruktur erhalten Sie alle Daten einfach und für ihr Problem zugeschnitten.'
                    },
                    card4: {
                        title: 'Umfangreiche Analyse',
                        text: 'Unsere Engine bedient sich der neuesten Technologien in den Bereichen der Satelliten- und Bodendaten um die umfangreichste Analyse zu ermöglichen.'
                    }
                }
            }
        },
        noTimeToWaste: {
            title: 'KEINE ZEIT',
            primary: ' VERLIEREN.',
            text: 'Der Klimawandel und seine Auswirkungen auf unsere Gesellschaft gelten als eine der existenziellsten Bedrohungen unserer Zeit. Nur durch daten-basierte Entscheidungen schaffen wir es unsere Ökosysteme klimaresilient zu adaptieren und permanente CO2 Senken zu schaffen.',
            footerText: 'CarbonStack hilft Ihnen dabei den richtigen Baum am richtigen Ort zur richtigen Zeit zu pflanzen. Ganz gleich, ob Sie ein Aufforstungsprojekt entwickeln oder Ihre Ökosysteme anpassen, unsere Daten gewährleisten Dauerhaftigkeit und Widerstandsfähigkeit.',
            cardsBlock: {
                title: 'Lokale Verantwortung übernehmen',
                subtitle: 'Die globale Erwärmung ist bereits in Deutschland angekommen. Lasst uns etwas dagegen tun.',
                cards: {
                    card1: {
                        title: 'Signifikante Waldschäden',
                        text: 'Messbarer Nadel- oder Blattverlust der Baumkrone',
                        percentages: '37'
                    },
                    card2: {
                        title: 'Gefährdet',
                        text: 'Eine Warnung wurde bereits für diese Gebiete ausgesprochen',
                        percentages: '42'
                    },
                    card3: {
                        title: 'Keine Schäden',
                        text: 'Gesunde Waldflächen',
                        percentages: '21'
                    }
                }
            }
        },
        needToPlantNow: {
            titleP1: 'WIR MÜSSEN ',
            titleP2: 'PFLANZEN, UM BIS',
            titleP3: 'AUSGEWACHSENE BÄUME ZU HABEN',
            primary1: 'JETZT',
            primary2: '2050 ',
            imgText: 'Bereits gepflanzte Bäume',
            button: 'Kontaktieren Sie uns'
        },
        permananceAdditionality: {
            title: 'Dauerhaftigkeit & Zusätzlichkeit.',
            text: 'Unsere CarbonStack Engine ermöglicht eine bislang nicht eingesetze Genauigkeit in die Bestimmung von CO2 Aufnahme-kapazitäten von Ökosystemen.',
            cards: {
                card1: {
                    title: 'Fernerkundung',
                    text: 'Wir verwenden Satellitenbilder, um die genaue Größe des beschädigten Bereichs zu bestimmen. Dies geschieht sowohl mit Sentinel- als auch mit Airbus Pleiades Neo-Bildern.'
                },
                card2: {
                    title: 'Klimamodellierung',
                    text: 'Mit Hilfe unserer prozess-basierten Umweltsimulation wird die Kohlenstoffaufnahme des Standorts in den nächsten 30 bis 60 Jahren ermittelt. Historische Wetterdaten, Klimamodelle und bis zu 70 Parameter pro Baumart werden verwendet.'
                },
                card3: {
                    title: 'Zertifizierung',
                    text: 'Alle Projektflächen werden von unabhängigen Standards und Gutachtern überprüft. Wir bieten Unternehmen somit ein transparentes und nachvollziehbares Engagement für das Klima, welches konform mit ihrer ESG-Berichterstattung ist.'
                }
            }
        },
        transparentOffset: {
            title: 'Eine vertrauenswürdige Infrastruktur für ',
            primary: 'transparente Kompensation.',
            subtitle: 'Wir haben eine Blockchain-basierte Plattform gebaut, die es Unternehmen ermöglicht, ihren Beitrag zu visualisieren.',
            text: 'Durch die Implementierung eines privaten Zweigs der Algorand-Blockchain-Infrastruktur lösen wir aktiv die Herausforderung, ein transparentes Register für Klimaschutzprojekte zu haben. Mit dem Fokus auf Nachhaltigkeit ermöglicht uns das Algorand-Protokoll, dies auf die energieeffizienteste Art und Weise zu implementieren, indem es seinen reinen Proof-of-Stake-Algorithmus nutzt. Mit dieser Blockchain-Infrastruktur haben wir ein umfassendes Blockchain-basiertes Carbon Registry geschaffen, das Kompensationsprojekte über ihren gesamten Lebenszyklus hinweg transparent dokumentiert. Darüber hinaus ermöglicht die dezentrale Struktur unseres Registers die aktive Beteiligung externer Nutzer, die durch einen vollständig dezentralen Konsensusalgorithmus unterstützt wird.',
            trustedByTitle: 'Unserem Register vertraut:'
        },
        observeFromSpace: {
            title: 'Beobachten Sie Ihr Waldprojekt aus dem ',
            subtitle: 'Anstelle von manuellen Stichproben überwachen wir jeden einzelnen Baum unserer Projekte über Satellitenbilder. Unternehmen können sich einen jährlichen Überblick über das Waldwachstum verschaffen.',
            primary1: 'Weltraum!',
            primary2: '1,5 MILLIONEN BÄUME',
            primary3: '30',
            text1: 'Wurden analysiert, um unsere Simulation durch maschinelles Lernen zu verbessern. Wir arbeiten dabei eng mit Airbus und UP42 zusammen, wenn es um die Aufnahme der erforderlichen Bilder geht.',
            text2: 'Auflösung der Airbus Pleiades Neo Satelliten ermöglicht es uns, jeden einzelnen Baum auf unseren Projektstandorten zu identifizieren und zu klassifizieren.',
            button: 'Fallstudie'
        }
    }
};
