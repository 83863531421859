export const aboutUsPageData = {
    en: {
        metaData: {
            author: 'Noah Winneberger',
            description: 'Join the Team and help us to create climate resilient forests. Use your skillset to tackle climate change.',
            keywords: 'sustainability, carbon offsets, afforestation, climate, transformation, carbon neutral, carbon neutrality, zero emissions, emissions, forests, blockchain, algorand, transparency, transparent, German forests, compensation, compensation claim, contribution claim, carbon certificate, satellite, remote sensing, offsets, offsetting',
            language: 'English',
            robots: 'index, follow',
            pageTitle: 'CarbonStack - About Us',
            title: 'Join Our Mission for Transparent Offsets'
        },
        hero: {
            title: 'HELP US',
            title2: 'SAVE THE',
            primary: 'PLANET.',
            subtitle: 'Join our team and work with us on the greatest challenge of our generation.',
            button: 'Join CarbonStack!'
        },
        roadToNetZero: {
            title: 'Our Road to Net-Zero',
            text: 'Our vision as a team is to contribute towards a net negative world by 2050. By then we will need more carbon uptake than emission in order to reach the 1.5°C target. We think that every company has to go through the transition of avoiding, reducing and compensating their emissions. For the latter, we offer solutions to create truly transparent and permanent carbon stacks.'
        },
        founders: {
            title: 'Our Founders',
            text: 'CarbonStack was founded in July 2022. Since then, we have built up offices in Hamburg and Kiel with key personell in the fields of remote sensing, blockchain and environmental modelling.'
        },
        partners: {
            title: 'Supported by'
        }
    },
    de: {
        metaData: {
            author: 'Noah Winneberger',
            description: 'Werde Teil des Teams und hilf uns dabei, klimaresistente Wälder zu schaffen. Nutz deine Ihre Fähigkeiten, um den Klimawandel zu bekämpfen.',
            keywords: 'sustainability, carbon offsets, afforestation, climate, transformation, carbon neutral, carbon neutrality, zero emissions, emissions, forests, blockchain, algorand, transparency, transparent, German forests, compensation, compensation claim, contribution claim, carbon certificate, satellite, remote sensing, offsets, offsetting, team, skills',
            language: 'Deutsch',
            robots: 'index, follow',
            pageTitle: 'CarbonStack - Über Uns',
            title: 'Accelerating the transformation towards Climate Neutrality'
        },
        hero: {
            title: 'EIN TEAM MIT',
            title2: '',
            primary: 'ENORMER WIRKKRAFT.',
            subtitle: 'Wir bieten Unternehmen ein nachhaltiges Investment in lokale Klimaschutzprojekte, um gemeinsam an der größten Herausforderung unserer Zeit zu arbeiten.',
            button: 'Join CarbonStack!'
        },
        roadToNetZero: {
            title: 'Für die Generationen von morgen',
            text: 'Unsere Vision als Team ist es, bis 2050 zu einer netto negativen Welt beizutragen. Wir werden bis dahin mehr Kohlenstoff aufnehmen als ausstoßen müssen, um das 1,5°C-Ziel zu erreichen. Unser Angebot richtet sich an Unternehmen, die durch einen messbaren Beitrag eine Vorreiterrolle beim Klimaschutz in Deutschland einnehmen wollen.',
            button: 'Join CarbonStack!'
	},
        founders: {
            title: 'Unsere Gründer',
            text: 'CarbonStack wurde im Juli 2022 gegründet. Seitdem bauen wir Büros in Hamburg und Kiel mit Schlüsselpersonen in den Bereichen Fernerkundung, Blockchain und Umweltmodellierung auf.'
        },
        partners: {
            title: 'Förderungen und Auszeichnungen'
        }
    }
};
