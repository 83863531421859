// import lib components and functions
import { Link } from 'react-router-dom';

function MainNavigationItem(props) {
  const { onClickHandler, langId, pagePathname, path, title } = props;

  return (
    <li className="main__nav__item" onClick={() => onClickHandler}>
      <Link className={pagePathname === path ? 'active' : 'link'} to={`/${langId}/${path}`}>
        {title}
      </Link>
    </li>
  );
}

export default MainNavigationItem;
