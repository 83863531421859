// import lib components and functions
import { useContext } from 'react';

// import custom components and functions
import MetaTags from '../MetaTags/MetaTags';
import Layout from '../../layout/Layout';
import Hero from './sections/Hero';
import CSReduceAvoid from './sections/CSReduceAvoidSection/CSReduceAvoid';
import NoWasteTime from './sections/NoWasteTimeSection/NoWasteTime';
import PermanenceAdditionality from './sections/PermanenceAdditionalitySection/PermanenceAdditionality';
import TransparentOffset from './sections/TransparentOffsetSection/TransparentOffset';
import ObserveFromSpace from './sections/ObserveFromSpaceSection/ObserveFromSpace';
import NeedToPlant from './sections/NeedToPlantSection/NeedToPlant';

import { LanguageContext } from '../../../store/switchLanguageContext';
import { ThemeContext } from '../../../store/switchThemeContext';
import { useTranslation } from '../../../store/switchTranslation';
import { homePageData } from '../../../store/home';
// import assets
import '../../../styles/homePage.scss';

function Home() {
  const translation = useTranslation(homePageData, LanguageContext);
  const {
    metaData,
    hero,
    avoidReduce,
    noTimeToWaste,
    needToPlantNow,
    permananceAdditionality,
    transparentOffset,
    observeFromSpace
  } = translation;

  const themeCtx = useContext(ThemeContext);

  return (
    <>
      <MetaTags metaData={metaData} />
      <Layout>
        <Hero textData={hero} />
        <CSReduceAvoid textData={avoidReduce} />
        <NoWasteTime textData={noTimeToWaste} theme={themeCtx.theme} />
        <NeedToPlant textData={needToPlantNow} />
        <PermanenceAdditionality textData={permananceAdditionality} />
        {/* <TransparentOffset textData={transparentOffset} theme={themeCtx.theme} /> */}
        <ObserveFromSpace textData={observeFromSpace} />
      </Layout>
    </>
  );
}

export default Home;
