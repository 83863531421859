import { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext({
  themeId: 'light',
  setTheme: function () {}
});

export function ThemeContextProvider(props) {
  const [theme, setTheme] = useState('light');

  function switchThemeHandler() {
    theme === 'light' ? setTheme('dark') : setTheme('light');
  }

  useEffect(() => {
    const body = document.querySelector('body');
    if (body.classList.contains('light')) {
      body.classList.remove('light');
      body.classList.add(theme);
    } else {
      body.classList.remove('dark');
      body.classList.add(theme);
    }
  }, [theme]);

  const context = {
    theme,
    switchTheme: switchThemeHandler
  };

  return <ThemeContext.Provider value={context}>{props.children}</ThemeContext.Provider>;
}
