// import lib components and functions
import { useState, useEffect } from 'react';

// import custom components and functions
import Video from '../../../ui/Video';

// import assets
import poster from '../../../../assets/startingPage/homePagePoster.png';
import '../../../../styles/mouseWheel.css';

function Hero(props) {
  const [viewportSize, setViewportSize] = useState({
    width: null,
    height: null
  });

  useEffect(() => {
    function setDimensionsOnResize() {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    setDimensionsOnResize();
    window.addEventListener('resize', setDimensionsOnResize);

    return window.removeEventListener('resize', setDimensionsOnResize);
  }, [viewportSize.width]);

  function scrollDownHandler() {
    window.scrollTo({
      top: viewportSize.height,
      behavior: 'smooth'
    });
  }

  const { title, primary, text } = props.textData;

  return (
    <section className="full__screen">
      <div className="video__wrap__bg">
        <Video
          className="video__bg"
          poster={poster}
          src="https://youtu.be/kfAlhfXjSsU?&autoplay=1&modestbranding=1&showinfo=0&start=0&enablejsapi=1&mute=1"
          videoId="kfAlhfXjSsU"
          switchSound={false}
        />
        <div className="section__title__block">
          <h1>
            {title} <span className="primary">{primary}</span>
          </h1>
          <p>{text}</p>
        </div>
        <button
          onClick={scrollDownHandler}
          className="scroll__btn container justify__center align__center">
          <div class="icon-scroll">
            <div class="mouse">
              <div class="wheel"></div>
            </div>
          </div>
        </button>
      </div>
    </section>
  );
}

export default Hero;
