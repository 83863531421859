// import lib components and functions
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// import custom components and functions
import Logo from './Logo';
import ContactForm from './ContactForm';
import Modal from './Modal';
import Footer from './Footer';
import MainNavigation from './MainNavigation';
import MainWrapper from './MainWrapper';
import { LanguageContext } from '../../store/switchLanguageContext';
import { ThemeContext } from '../../store/switchThemeContext';

// import assets
import lightThemeIcon from '../../assets/layout/lgihtThemeIcon.svg';
import darkThemeIcon from '../../assets/layout/darkThemeIcon.png';
import '../../styles/navigationBar.scss';

function Layout(props) {
  const EN = 'English';
  const DE = 'Deutsch';

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const langCtx = useContext(LanguageContext);
  const themeCtx = useContext(ThemeContext);

  const navigate = useNavigate();
  const params = useParams();
  let langId = params.langId;
  if (!langId) {
    langId = 'en';
  }
  if (langId !== langCtx.langId && langId !== 'de') {
    langId = langCtx.langId;
  }

  const location = useLocation();
  let pathName = location.pathname.replace(`/${langId}`, '');

  const [state, setState] = useState({ isMenuOpened: false });
  const [winWidth, setWinwidth] = useState(1920);

  function toggleMenuHandler() {
    const isMenuOpened = state.isMenuOpened ? false : true;
    setState((state) => ({ ...state, isMenuOpened }));
  }

  function switchLanguage(language, langId) {
    const pathArr = pathName.split('/');
    let pageId = pathArr.find((pathId) => pathId === 'about-us' || pathId === 'our-impact' || '');
    if (!pageId) {
      pageId = '';
    }

    const path = `/${langId}/${pageId}`;
    navigate(path, { replace: true });
    langCtx.switchLanguage(language, langId);
  }

  useEffect(() => {
    const language = langId === 'en' ? DE : EN;
    switchLanguage(language, langId);
  }, []);

  function changeLangHandler() {
    let lang = langCtx.language;
    if (lang === DE) {
      switchLanguage(EN, 'de');
    } else {
      switchLanguage(DE, 'en');
    }
  }

  useEffect(() => {
    if (state.isMenuOpened) {
      document.body.classList.add('locked');
    } else {
      document.body.classList.remove('locked');
    }
  }, [state.isMenuOpened]);

  useEffect(() => {
    function onResize() {
      setWinwidth(window.innerWidth);
    }
    window.addEventListener('resize', onResize);

    return window.removeEventListener('resize', onResize);
  }, []);

  return (
    <>
      <header className="main__header">
        <MainWrapper>
          <nav className="nav__wrap container align__center">
            <Logo langId={langId} />
            <div
              className={
                state.isMenuOpened ? 'main__navigation__wrap active' : 'main__navigation__wrap'
              }>
              <div className="container justify__between main__navigation align__center">
                <MainNavigation
                  onClickHandler={winWidth < 768 ? toggleMenuHandler : undefined}
                  langId={langId}
                />
                <div className="container nav__buttons__block justify__end align__center">
                  <button onClick={themeCtx.switchTheme} className="switch__theme__btn">
                    <div className="switch__wrapper">
                      <div className="switch__thumb">
                        <img
                          src={themeCtx.theme === 'light' ? lightThemeIcon : darkThemeIcon}
                          alt="Change theme button"
                        />
                      </div>
                    </div>
                  </button>
      		  <div>
                    <button onClick={openModal} className="main__link">
                      <span>Kontakt</span>
                    </button>

                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                      <ContactForm onSubmit={closeModal} />
                    </Modal>
                  </div>
	          <a href="https://www.carbonstack.io" className="login main__contact">
                      Login
                   </a>
                </div>
              </div>
            </div>
            <button
              onClick={toggleMenuHandler}
              className={state.isMenuOpened ? 'nav__burger active' : 'nav__burger'}>
              <span></span>
            </button>
          </nav>
        </MainWrapper>
      </header>
      <main>{props.children}</main>
      <Footer
        lang={langCtx.language}
        langId={langCtx.langId}
        changeLangHandler={changeLangHandler}
      />
    </>
  );
}

export default Layout;
