// import lib components and functions
import { HashLink } from 'react-router-hash-link';

// import assets
import buyCCMap from '../../../../../assets/startingPage/buyCCMap.svg';
import remoteSensingSVG from '../../../../../assets/startingPage/remoteSensing.svg';
import transparentDocumentationSVG from '../../../../../assets/startingPage/transparentDocumentation.svg';
import arrowIcon from '../../../../../assets/startingPage/arrow.svg';

function CSACards(props) {
  const { card1, card2, card3, card4 } = props.textData;

  return (
    <div className="csa__cards container align__stretch justify__center">
      <div className="csa__card__wrap container align__center small">
        <h4 className="csa__card__title">{card1.title}</h4>
        <p className="csa info__text">{card1.text}</p>
        <img src={buyCCMap} className="img" alt="buyCCMap" />
      </div>
      <img src={arrowIcon} alt="arrow" className="csa__arrow img" />
      <div className="csa__card__wrap container align__center large">
        <h4 className="csa__card__title">{card2.title}</h4>
        <p className="csa info__text">{card2.text}</p>
        <div className="container align__start inner__card__wrap">
          <div className="csa__card__wrap container align__center">
            <h5 className="csa__card__title">{card3.title}</h5>
            <p className="csa info__text">{card3.text}</p>
            <img
              src={transparentDocumentationSVG}
              className="img"
              alt="transparent documentation"
            />
          </div>
          <div className="csa__card__wrap container align__center">
            <h5 className="csa__card__title">{card4.title}</h5>
            <p className="csa info__text">{card4.text}</p>
            <img src={remoteSensingSVG} className="img" alt="remote sensing" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CSACards;
