// import lib components and functions
import { useLocation } from 'react-router-dom';

// import custom components and functions
import { navbarData } from '../../store/layout';
import { LanguageContext } from '../../store/switchLanguageContext';
import { useTranslation } from '../../store/switchTranslation';
import MainNavigationItem from './MainNavigationItem';

function MainNavigation(props) {
  const translation = useTranslation(navbarData, LanguageContext);
  const { aboutUs, ourImpact } = translation;

  const location = useLocation();
  const { pathname } = location;
  const pagePathname = pathname.split('/')[2];

  const { onClickHandler, langId } = props;
  return (
    <ul className="container justify__start align__center">
      <MainNavigationItem
        onClickHandler={onClickHandler}
        pagePathname={pagePathname}
        title={aboutUs}
        path={'about-us'}
        langId={langId}
      />
      <MainNavigationItem
        onClickHandler={onClickHandler}
        pagePathname={pagePathname}
        title={ourImpact}
        path={'our-impact'}
        langId={langId}
      />
    </ul>
  );
}

export default MainNavigation;
