import { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext({
  langId: 'de',
  setLanguage: function () {}
});

export function LanguageContextProvider(props) {
  const [languageState, setLanguageState] = useState({ langId: 'de', language: 'English' });

  function switchLanguageHandler(language, langId) {
    setLanguageState({ language, langId });
  }

  useEffect(() => {
    const langId = languageState.langId;
    document.querySelector('html').setAttribute('lang', langId);
    const body = document.querySelector('body');
    if (body.classList.contains('de')) {
      body.classList.remove('de');
      body.classList.add(langId);
    } else {
      body.classList.remove('en');
      body.classList.add(langId);
    }
  }, [languageState]);

  const context = {
    langId: languageState.langId,
    language: languageState.language,
    switchLanguage: switchLanguageHandler
  };
  return <LanguageContext.Provider value={context}>{props.children}</LanguageContext.Provider>;
}
