import React from 'react';

function CO2Icon() {
  return (
    <div className="case__card__icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="141" height="113" viewBox="0 0 141 113">
        <g id="Group_24491" data-name="Group 24491" transform="translate(-790 -2242)">
          <rect
            id="Rectangle_4997"
            data-name="Rectangle 4997"
            width="111"
            height="64"
            rx="32"
            transform="translate(806 2291)"
            fill="#e7e6e6"
          />
          <circle
            id="Ellipse_13237"
            data-name="Ellipse 13237"
            cx="40.5"
            cy="40.5"
            r="40.5"
            transform="translate(850 2274)"
            fill="#e7e6e6"
          />
          <circle
            id="Ellipse_13238"
            data-name="Ellipse 13238"
            cx="40.5"
            cy="40.5"
            r="40.5"
            transform="translate(810 2242)"
            fill="#e7e6e6"
          />
          <circle
            id="Ellipse_13239"
            data-name="Ellipse 13239"
            cx="40.5"
            cy="40.5"
            r="40.5"
            transform="translate(790 2274)"
            fill="#e7e6e6"
          />
          <text id="CO2" transform="translate(830 2319)">
            <tspan x="0" y="0">
              CO
            </tspan>
            <tspan y="0" fontSize="19.832" baselineShift="-11.332199483839085">
              2
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
}

export default CO2Icon;
