export const ourImpactPageData = {
    en: {
        metaData: {
            author: 'Noah Winneberger',
            description: 'CarbonStack helps companies reach their sustainability goals by developing local afforestation projects and creating transparent carbon credits.',
            keywords: 'sustainability, carbon offsets, afforestation, climate, transformation, carbon neutral, carbon neutrality, zero emissions, emissions, forests, blockchain, algorand, transparency, transparent, German forests, compensation, compensation claim, contribution claim, carbon certificate, satellite, remote sensing, offsets, offsetting',
            language: 'English',
            robots: 'index, follow',
            pageTitle: 'CarbonStack - Our Impact',
            title: 'Climate resilient Forest in Germany'
        },
        hero: {
            title: 'PLANTING FORESTS FOR ',
            primary: 'NET-ZERO.',
            subtitle: 'Through afforestation, we uptake carbon from the atmosphere to offset unavoidable emissions from Europe. In Germany, we have already planted over 120.000 trees.'
        },
        caseSection: {
            title: 'Case',
            subtitle: 'Complete reforestation of the damaged',
            subtitle2: 'forests',
            primary: 'Harz',
            text: 'In cooperation with Shell Deutschland GmbH, we target to reinstate a healthy and gapless forest in the Harz. Over the past 5 years there has been significant forest damage, destroying up to 40% of the total forest stand.',
            cards: {
                title1: 'Impact so far',
                title2: 'Our ambition for 2030',
                card1: {
                    title: 'Trees planted so far',
                    primary: '21.275'
                },
                card2: {
                    title: 'Tons of CO2e stored',
                    primary: '1.206'
                },
                card3: {
                    title: 'Trees planted',
                    primary: '22 Million'
                },
                card4: {
                    title: 'Tons of CO2e stored',
                    primary: '3,5 Million'
                }
            }
        },
        impactGrid: {
            text: '18ha of new, climate resilient forest in the Harz',
            subtitle: 'Setup your company project today',
            primary: 'SPRING 2022',
            button: 'Take the first step'
        }
    },
    de: {
        metaData: {
            author: 'Noah Winneberger',
            description: 'CarbonStack helps companies reach their sustainability goals by developing local afforestation projects and creating transparent carbon credits.',
            keywords: 'sustainability, carbon offsets, afforestation, climate, transformation, carbon neutral, carbon neutrality, zero emissions, emissions, forests, blockchain, algorand, transparency, transparent, German forests, compensation, compensation claim, contribution claim, carbon certificate, satellite, remote sensing, offsets, offsetting',
            language: 'Deutsch',
            robots: 'index, follow',
            pageTitle: 'CarbonStack - Unser Impact',
            title: 'Klima-resilienter Wald in Deutschland'
        },
        hero: {
            title: 'LANGFRISTIGE ',
            primary: 'KOHLENSTOFFSPEICHER.',
            subtitle: 'Durch die Aufforstung nehmen wir Kohlenstoff aus der Atmosphäre auf, um unvermeidbare Emissionen aus Europa langfristig zu speichern. In Deutschland haben wir bereits über 120.000 Bäume gepflanzt.'
        },
        caseSection: {
            title: 'Waldprojekt im Harz',
            subtitle: 'Wiederaufforstung einer Schadfläche',
            subtitle2: null,
            primary: 'im Südharz',
            text: 'In Zusammenarbeit mit Shell Deutschland GmbH stellen wir einen gesunden und lückenlosen Wald im Harz wieder her. In den letzten 5 Jahren kam es hier zu erheblichen Waldschäden von bis zu 40% des gesamten Waldbestandes.',
            cards: {
                title1: 'Stand Frühjahr 2022',
                title2: 'Unsere Absicht bis 2030',
                card1: {
                    title: 'Bereits gepflanzte Bäume',
                    primary: '21.275'
                },
                card2: {
                    title: 'Tonnen CO2e gespeichert',
                    primary: '1.206'
                },
                card3: {
                    title: 'Bäume gepflanzt',
                    primary: '22 Millionen'
                },
                card4: {
                    title: 'Tonnen CO2e gespeichert',
                    primary: '3,5 Millionen'
                }
            }
        },
        impactGrid: {
            text: 'Auf 18ha haben wir einen neuen, klimaresistenten Mischwald gepflanzt',
            subtitle: 'Richten Sie Ihr Unternehmensprojekt noch heute ein',
            primary: 'FRÜHLING 2022',
            button: 'Gehe den ersten Schritt'
        }
    }
};
