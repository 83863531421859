// import custom components and functions
import MainWrapper from '../../../layout/MainWrapper';
import teamImg from '../../../../assets/aboutUs/team.jpeg';

// import MainWrapper from '../../../layout/MainWrapper';
import CardLayout from '../../../ui/CardLayout';

// import assets
// import teamImg from '../../../../assets/aboutUs/team.jpeg';
import '../../../../styles/aboutUs.scss';

function RoadToNetZero(props) {
  // const { title, button, text } = props.textData;
  const { button, text, primary, subtitle, title, title2 } = props.textData;

  return (
    <section className="net__zero">
      <MainWrapper>
        <div className="title__block about__us">
          <h2>{title}</h2>
          <p>{text}</p>
        <CardLayout>
	  <img src={teamImg} className="img" alt="Carbonstack team" />
        </CardLayout>
	<a href="https://join.com/companies/carbonstack" className="main__link">
          {button}
        </a>
	</div>
      </MainWrapper>
    </section>
  );
}

export default RoadToNetZero;
