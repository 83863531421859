// import custom components and functions
import Logo from './Logo';
import MainWrapper from './MainWrapper';
import { footerData } from '../../store/layout';
import { useTranslation } from '../../store/switchTranslation';
import { LanguageContext } from '../../store/switchLanguageContext';
import MainNavigationItem from './MainNavigationItem';
import { useLocation } from 'react-router-dom';


// import assets
import emailIcon from '../../assets/startingPage/emailContact.svg';
import phoneIcon from '../../assets/startingPage/telephoneIcon.svg';
import linkedInIcon from '../../assets/startingPage/linkedInIcon.svg';
import switchLangIcon from '../../assets/startingPage/langSwitch.svg';

import '../../styles/footer.scss';

function Footer(props) {
  const email = 'info@carbonstack.de';
  const phone = '+49 (0)173 658 18 98';

  const translation = useTranslation(footerData, LanguageContext);
  const { contact, stayUpToDate, imprint, policy } = translation;

  const location = useLocation();
  const { pathname } = location;
  const pagePathname = pathname.split('/')[2];

  const { onClickHandler, langId } = props;

  return (
    <footer className="footer">
      <MainWrapper>
        <div className="footer__container container justify__center align__start">
          <div className="footer__block">
            <Logo langId={props.langId} />
            <div className="footer__btns__block container align__center">
              <button
                disabled={false}
                onClick={props.changeLangHandler}
                className="container align__center justify__start lang__switch__btn">
                <img src={switchLangIcon} alt="switch language" />
                <span>{props.lang}</span>
              </button>
              <ul className="container justify__start align__center">
                <MainNavigationItem
                  onClickHandler={onClickHandler}
                  pagePathname={pagePathname}
                  title={imprint}
                  path={'imprint'}
                  langId={langId}
                />
                <MainNavigationItem
                  onClickHandler={onClickHandler}
                  pagePathname={pagePathname}
                  title={policy}
                  path={'privacy'}
                  langId={langId}
                />
              </ul>
            </div>
          </div>
          <div className="footer__block">
            <h3 className="footer__block__title">{contact}</h3>
            <p className="footer__block__text">CarbonStack GmbH</p>
            <address className="footer__block__text">
              Am Sandtorkai 32, 20457 Hamburg, Germany
            </address>
            <div className="footer__links">
              <a
		  href='https://webforms.pipedrive.com/f/6xUy4jEsNQ780RHowqHkn3D6Elbngl6xZmYO7LRab2isud81tREjoRLCcNAQ0vyPrZ'
		  target='_blank'
		  onClick={() => {
		    const pdWebForms = document.querySelector('.pipedriveWebForms');
		    pdWebForms.style.display = 'block';
		  }}
		  className="footer__block__link container justify__start align__center"
		>
		  <span>Kontaktformular</span>
		</a>
		<div
		  className="pipedriveWebForms"
		  style={{ display: 'none' }}
		  data-pd-webforms="https://webforms.pipedrive.com/f/6xUy4jEsNQ780RHowqHkn3D6Elbngl6xZmYO7LRab2isud81tREjoRLCcNAQ0vyPrZ"
		></div>
              <a
                href={'tel:' + phone}
                className="footer__block__link container justify__start align__center">
                <img src={phoneIcon} alt="contact phone" />
                <span>040 555 77 873</span>
              </a>
            </div>
          </div>
          <div className="footer__block">
            <h3 className="footer__block__title">{stayUpToDate}</h3>
            <a
              href="https://www.linkedin.com/company/carbonstack"
              target="_blank"
              rel="noreferrer"
              className="footer__block__link container justify__start align__center">
              <img src={linkedInIcon} alt="LinkedIn" />
              <span>LinkedIn</span>
            </a>
            <a
              href="https://www.youtube.com/@carbonstackgmbh/featured"
              target="_blank"
              rel="noreferrer"
              className="footer__block__link container justify__start align__center">
              <span>YouTube</span>
            </a>
          </div>
        </div>
      </MainWrapper>
    </footer>
  );
}

export default Footer;
