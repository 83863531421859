import { useState } from 'react';
import ReactPlayer from 'react-player/lazy';

// custom components
import SoundSwitchBtn from './SoundSwitchBtn';

// import assets
import '../../styles/video.scss';

function Video(props) {
  const { className, poster, src, videoId, switchSound } = props;
  const [soundState, setSoundState] = useState({ isMuted: true, volume: 0 });

  function soundStateHandler() {
    setSoundState((state) => ({ isMuted: !state.isMuted, volume: state.volume === 0 ? 0.5 : 0 }));
  }

  return (
    <>
      <div className={className}>
        <ReactPlayer
          url={src}
          controls={false}
          loop={true}
          palying="true"
          volume={soundState.volume}
          muted={soundState.isMuted}
          className={'video'}
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: {
                showInfo: 0,
                modestBranding: 1,
                playlist: videoId,
                autoplay: 1,
                disabledkb: 1
              }
            }
          }}
        />
      </div>
      <img src={poster} className="poster__img" alt="poster" />
      {switchSound && (
        <SoundSwitchBtn isMuted={soundState.isMuted} soundStateHandler={soundStateHandler} />
      )}
    </>
  );
}

export default Video;
