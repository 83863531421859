// import custom components and functions
import MetaTags from '../MetaTags/MetaTags';
import Layout from '../../layout/Layout';
import Hero from './sections/Hero';
import Case from './sections/CaseSection/Case';
import ImpactGrid from './sections/ImpactGrid';
import { LanguageContext } from '../../../store/switchLanguageContext';
import { useTranslation } from '../../../store/switchTranslation';
import { ourImpactPageData } from '../../../store/ourImpact';

function OurImpact() {
  const translation = useTranslation(ourImpactPageData, LanguageContext);
  const { caseSection, hero, impactGrid, metaData } = translation;

  return (
    <>
      <MetaTags metaData={metaData} />
      <Layout>
        <Hero textData={hero} />
        <Case textData={caseSection} />
        <ImpactGrid textData={impactGrid} />
      </Layout>
    </>
  );
}

export default OurImpact;
