import CO2Icon from '../../../../ui/icons/CO2Icon';

function CaseCard(props) {
  const { imgSrc, title, textData } = props;

  return (
    <div className="case__card container align__center">
      <div className="card__icon__wrap">
        {imgSrc ? <img src={imgSrc} alt={title} className="case__card__icon img" /> : <CO2Icon />}
      </div>

      <h4 className="case__card__title">{title}</h4>
      <span className="primary">{textData}</span>
    </div>
  );
}

export default CaseCard;
