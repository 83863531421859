// import custom components and functions
import MainWrapper from '../../../layout/MainWrapper';

// import assets
import partner1 from '../../../../assets/aboutUs/pr1.png';
import partner2 from '../../../../assets/aboutUs/pr2.png';
import partner3 from '../../../../assets/aboutUs/pr3.jpeg';
import partner4 from '../../../../assets/aboutUs/pr4.jpeg';
import partner5 from '../../../../assets/aboutUs/pr5.jpeg';
import partner6 from '../../../../assets/aboutUs/IFBLogo.svg';
import partner7 from '../../../../assets/aboutUs/algorandLogo.svg';

function Partners(props) {
  return (
    <section className="partners">
      <MainWrapper>
        <div className="title__block about__us">
          <h2>{props.textData.title}</h2>
        </div>
        <div className="partners__wrapper container align__center justify__center">
          <div className="img__block container align__center justify__center">
            <img src={partner1} className="img" alt="Bundersministerium" />
          </div>
          <div className="img__block container align__center justify__center">
            <img src={partner2} className="img" alt="Exist" />
          </div>
          <div className="img__block container align__center justify__center">
            <img src={partner3} className="img" alt="ESF" />
          </div>
          <div className="img__block container align__center justify__center">
            <img src={partner4} className="img" alt="European Union" />
          </div>
          <div className="img__block container align__center justify__center">
            <img src={partner5} className="img" alt="Zusammen. Zukunft. Gestalten." />
          </div>
        </div>
        <div className="container justify__between partners__container">
          <img src={partner6} alt="IFB" className="pr__1 img" />
          <img src={partner7} alt="Algorant" className="pr__2 img" />
        </div>
      </MainWrapper>
    </section>
  );
}

export default Partners;
