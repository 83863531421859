export const navbarData = {
  en: {
    aboutUs: 'About Us',
    ourImpact: 'Our Impact'
  },
  de: {
    aboutUs: 'Über Uns',
    ourImpact: 'Lokale Wirkung'
  }
};
export const footerData = {
  en: {
    contact: 'Contact',
    stayUpToDate: 'Stay up to date',
    imprint: 'Imprint',
    policy: 'Privacy Policy'
  },
  de: {
    contact: 'Kontakt',
    stayUpToDate: 'Neuigkeiten',
    imprint: 'Impressum',
    policy: 'Datenschutzrichtlinien'
  }
};
